import React, { lazy, Suspense } from "react";
import HeroImg from "../assets/img/background.jpg";
import Logo from "../assets/img/tvcity_logo.png";
import Navbar from "../components/Navbar/Navbar";

// Diğer bileşenleri lazy load ile yüklemek
const Hero = lazy(() => import("../components/Hero/Hero"));
const Adventages = lazy(() => import("../components/Adventages/Adventages"));
const Services = lazy(() => import("../components/ServicesData/Services"));
const ConnectItem = lazy(() => import("../components/ConnectItem/ConnectItem"));
const ContactForm = lazy(() => import("../components/ContactForm/ContactForm"));
const InternetAbout = lazy(() => import("../components/InternetAbout/InternetAbout"));
const AboutUs = lazy(() => import("../components/AboutUs/AboutUs"));
const Footer = lazy(() => import("../components/Footer/Footer"));

function Home() {
  return (
    <>
      <Navbar />
      <Suspense fallback={<div>Loading...</div>}>
        <Hero
          cName="hero"
          heroImg={HeroImg}
          logo={Logo}
          title="Kabel Televiziyası"
          text="Limitsiz Fiber Optik İnternet"
          buttonText="Daxil ol"
        />
        <Adventages />
        <Services />
        <ConnectItem />
        <ContactForm/>
        <InternetAbout/>
        <AboutUs/>
        <Footer />
      </Suspense>
    </>
  );
}

export default Home;
