import React, { useState } from "react";
import axios from "axios";
import md5 from "md5";
import "./LoginForm.css";

const API_URL = "https://tvcity.az/api.php";

const LoginForm = ({ setIsLoggedIn }) => {
  const [username, setUsername] = useState("");
  const [errorMessages, setErrorMessages] = useState({});

  const errors = {
    username: "İstifadəçi adı tapılmadı",
    noUsername: "Zəhmət olmasa istifadəçi adınızı daxil edin",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!username.trim()) {
      setErrorMessages({ name: "noUsername", message: errors.noUsername });
      return;
    }

  

    const getUserInfo = async (code) => {
      let userCodes = await getUserName(code);
      if (userCodes.length === 0) {
          return null;
      }
      const userInfos = [];
      for (let i = 0; i < userCodes.length; i++) {
          let userCode = userCodes[i];
          let request = {userpass: md5(userCode)};
          let url = API_URL + '?saAPI.getInfo=' + JSON.stringify(request);
          await axios(url).then(response => {
              userInfos.push({...response.data.response, username: userCode});
          });
      }
      return userInfos;
    }

    const getUserName = async (code) => {
      let request = { userpass: md5(code) };
      let usercode = [];
      let url = API_URL + "?saAPI.getUserName=" + JSON.stringify(request);
      await axios(url).then((response) => {
        let data = response.data.response;
        if (data.TV_UserName) {
          usercode.push(data.TV_UserName);
      }
      if (data.IN_UserName) {
          usercode.push(data.IN_UserName);
      }

      });
      return usercode;
    };
   
    const userInfo = await getUserInfo(username); // Kullanıcı adını parametre olarak geçiriyoruz
    console.log(userInfo); // Kullanıcı bilgilerini konsola yazdır
    if (!userInfo || userInfo.length === 0) {
      setErrorMessages({ name: "username", message: errors.username });
      return;
    }
    // Kullanıcı bilgilerini localStorage'a kaydet
    localStorage.setItem("userInfo", JSON.stringify(userInfo));

    setIsLoggedIn(true);
  };

  const renderErrorMsg = (name) =>
    errorMessages.name === name   && (
      <p className="error_msg">{errorMessages.message}</p>
    );

  return (
    <div className="card">
      <h1 className="title">Daxil olun</h1>
      <p className="subtitle">İstifadəçi adını daxil edin.</p>
      <form onSubmit={handleSubmit}>
        <div className="inputs_container">
          <input
            type="text"
            placeholder="İstifadəçi adı"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          {renderErrorMsg("username")}
          {renderErrorMsg("noUsername")}
          
        </div>
        <input type="submit" value="Daxil ol" className="login_button" />
      </form>
    </div>
  );
};

export default LoginForm;
