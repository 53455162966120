import { useState } from "react";
import LoggedIn from "../components/Login/LoggedIn/LoggedIn";
import LoginForm from "../components/Login/LoginForm/LoginForm";

function Login() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <>
      {isLoggedIn ? (
        <LoggedIn setIsLoggedIn={setIsLoggedIn} />
      ) : (
        <LoginForm setIsLoggedIn={setIsLoggedIn} />
      )}
    </>
  );
}

export default Login;
