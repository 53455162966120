import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./LoggedIn.css";
import axios from "axios";

const LoggedIn = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const API_URL = "https://tvcity.az/api.php";

  useEffect(() => {
    // localStorage'dan kullanıcı bilgilerini al
    const storedUserInfo = localStorage.getItem("userInfo");
    if (storedUserInfo) {
      setUserInfo(JSON.parse(storedUserInfo));
      setIsLoggedIn(true);
    }
  }, []);

  const getBalance = () => {
    let value = 0;

    if (!selectedService) {
      return value;
    }

    let queryType = selectedService === "internet" ? 2 : 1;

    let selectedServiceObject;

    userInfo.forEach((info) => {
      if (+info.Type === +queryType) {
        selectedServiceObject = info;
      }
    });

    value = Number(selectedServiceObject.Balance);

    if (value < 0) {
      return value * -1;
    }
    return null;
  };

  useEffect(() => {
    setPaymentAmount(getBalance());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedService]);

  const formatPaymentDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("az-AZ", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  };

  const handleServiceChange = (e) => {
    setSelectedService(e.target.value);
  };

  const handlePaymentAmountChange = (e) => {
    let value = e.target.value;
    setPaymentAmount(isNaN(value) ? 0 : value.replace(/[a-zA-Z]/g, ""));
  };

  const createPayment = (amount, username) => {
    amount = amount.toFixed(2);
    amount = amount < 1000 ? amount : 1000;
    let url =
      API_URL + "?saAPI.payMillikart=" + JSON.stringify({ amount, username });
    axios(url).then((response) => {
      window.location.href = response.data.response.location;
    });
  };

  const handlePaymentSubmit = () => {
    console.log(paymentAmount, selectedService);
    if (selectedService !== "" && paymentAmount > 0) {
      const username =
        selectedService === "internet" && userInfo[1]
          ? userInfo[1].username
          : userInfo[0].username;
      createPayment(Number(paymentAmount), username);
    }
  };

  return (
    <>
      {isLoggedIn ? (
        <div className="user">
          <div className="user-name">
            <p>İstifadəçi: {userInfo && userInfo[0] && userInfo[0].FullName}</p>
          </div>
          <div className="user-dashboard">
            <div className="user-data">
              {userInfo[0] && (
                <div className="user-tv-data">
                  <h2>
                    {userInfo[0].Type === "1" ? "Rəqəmsal TV" : "İnternet"}
                  </h2>
                  <p>Tarif: {userInfo[0].Tarif}</p>
                  <p>Balans: {userInfo[0].Balance} ₼</p>
                  <p>
                    Son ödəniş tarixi:{" "}
                    {formatPaymentDate(userInfo[0].LastPaymentDate)}
                  </p>
                  <p>
                    Növbəti ödəniş tarixi:{" "}
                    {formatPaymentDate(userInfo[0].NextPaymentDate)}
                  </p>
                </div>
              )}
              {userInfo[1] && (
                <div className="user-net-data">
                  <h2>
                    {userInfo[1].Type === "1" ? "Rəqəmsal TV" : "İnternet"}
                  </h2>
                  <p>Tarif: {userInfo[1].Tarif}</p>
                  <p>Balans: {userInfo[1].Balance} ₼</p>
                  <p>
                    Son ödəniş tarixi:{" "}
                    {formatPaymentDate(userInfo[1].LastPaymentDate)}
                  </p>
                  <p>
                    Növbəti ödəniş tarixi:{" "}
                    {formatPaymentDate(userInfo[1].NextPaymentDate)}
                  </p>
                </div>
              )}
            </div>
            <div className="online-payment">
              <h2>Onlayn ödəniş</h2>
              <label>Xidməti seç:</label>

              <select name="service" onChange={handleServiceChange}>
                <option value="">Seçin</option>
                {userInfo && userInfo.length === 2 && (
                  <>
                    <option value="tv">Rəqəmsal TV</option>
                    <option value="internet">İnternet</option>
                  </>
                )}
                {userInfo && userInfo.length === 1 && (
                  <>
                    {userInfo[0] && userInfo[0].Type === "1" && (
                      <option value="tv">Rəqəmsal TV</option>
                    )}
                    {userInfo[0] && userInfo[0].Type === "2" && (
                      <option value="internet">İnternet</option>
                    )}
                  </>
                )}
              </select>

              <label>Məbləğ</label>
              <input
                type="text"
                value={paymentAmount}
                onChange={handlePaymentAmountChange}
              />
              <br />
              <button className="submit" onClick={handlePaymentSubmit}>
                Ödə
              </button>
            </div>
          </div>
          <Link to="/">
            <button className="back_button">Çıxış</button>
          </Link>
        </div>
      ) : (
        <div>
          <p>İstifadəçi məlumatlarına daxil olmaq mümkün olmadı.</p>
        </div>
      )}
    </>
  );
};

export default LoggedIn;
