import { Component } from "react";
import { Link as LinkRoll } from "react-scroll";
import Logo from "../../assets/img/logo.png";
import "./Navbar.css";

class Navbar extends Component {
  state = { clicked: false };
  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };
  closeMenu = () => {
    this.setState({ clicked: false });
  };
  render() {
    return (
      <nav className="NavbarItems">
        <h1 className="navbar-logo">
          <LinkRoll
            to="home"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
            onClick={this.closeMenu}
          >
            <img src={Logo} alt="" />
          </LinkRoll>
        </h1>

        <div className="menu-icons" onClick={this.handleClick}>
          <i
            className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}
          ></i>
        </div>

        <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
          <li>
            <LinkRoll
              className="nav-links"
              to="home"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              onClick={this.closeMenu}
            >
              <i className="fa-solid fa-house"></i>
              Əsas səhifə
            </LinkRoll>
          </li>
          <li>
            <LinkRoll
              className="nav-links"
              to="service"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
              onClick={this.closeMenu}
            >
              <i className="fa-solid fa-briefcase"></i>
              Xidmətlər
            </LinkRoll>
          </li>
          <li>
            <LinkRoll
              className="nav-links"
              to="connect"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              onClick={this.closeMenu}
            >
              <i className="fa-solid fa-house-signal"></i>
              Qoşulma
            </LinkRoll>
          </li>
          <li>
            <LinkRoll
              className="nav-links"
              to="contact"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
              onClick={this.closeMenu}
            >
              <i className="fa-solid fa-address-book"></i>
              Əlaqə
            </LinkRoll>
          </li>
                    
          <li>
            <LinkRoll
              className="nav-links"
              to="about"
              spy={true}
              smooth={true}
              offset={-93}
              duration={500}
              onClick={this.closeMenu}
            >
              <i className="fa-solid fa-circle-info"></i>
              Haqqımızda
            </LinkRoll>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Navbar;
